@import url(https://fonts.lug.ustc.edu.cn/css?family=Roboto);
@import url(https://fonts.lug.ustc.edu.cn/css?family=Roboto+Mono);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bottom .playing .control .material-icons, .bottom .actions .material-icons, .bottom .volume .material-icons, .list .entry .entry-actions .material-icons {
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  color: black;
  opacity: .54;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease; }
  .bottom .playing .control .material-icons:hover, .bottom .actions .material-icons:hover, .bottom .volume .material-icons:hover, .list .entry .entry-actions .material-icons:hover {
    opacity: .87; }
  .bottom .playing .control .primary.material-icons, .bottom .actions .primary.material-icons, .bottom .volume .primary.material-icons, .list .entry .entry-actions .primary.material-icons {
    opacity: .7;
    color: #00bcd4; }
    .bottom .playing .control .primary.material-icons:hover, .bottom .actions .primary.material-icons:hover, .bottom .volume .primary.material-icons:hover, .list .entry .entry-actions .primary.material-icons:hover {
      opacity: 1; }
  .bottom .playing .control .disabled.material-icons, .bottom .actions .disabled.material-icons, .bottom .volume .disabled.material-icons, .list .entry .entry-actions .disabled.material-icons {
    cursor: default;
    opacity: .20; }
    .bottom .playing .control .disabled.material-icons:hover, .bottom .actions .disabled.material-icons:hover, .bottom .volume .disabled.material-icons:hover, .list .entry .entry-actions .disabled.material-icons:hover {
      opacity: .20; }

.bottom .playing .timer, .bottom .volume .volume-text {
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-family: "Roboto Mono";
  color: rgba(0, 0, 0, 0.38); }

.frame {
  padding: 20px 40px;
  padding-bottom: 125px; }
  @media (max-width: 650px) {
    .frame {
      padding: 20px 20px;
      padding-bottom: 125px; } }

.top {
  max-width: 700px;
  margin: 0 auto; }

.bottom {
  position: fixed;
  height: 105px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.3) 0 -2px 6px;
  box-sizing: border-box;
  padding-top: 5px;
  background: white; }
  .bottom .progress {
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #9d9d9d; }
    .bottom .progress .progress-inner {
      -webkit-transition: -webkit-transform .2s ease;
      transition: -webkit-transform .2s ease;
      transition: transform .2s ease;
      transition: transform .2s ease, -webkit-transform .2s ease;
      background: #00bcd4;
      height: 100%;
      width: 100%; }
    .bottom .progress .progress-phantom {
      position: absolute;
      left: 0;
      top: 0;
      background: black;
      opacity: .2;
      height: 100%;
      width: 100%;
      display: none; }
    .bottom .progress.playing-progress {
      cursor: pointer; }
      .bottom .progress.playing-progress:hover .progress-phantom {
        display: block; }
  .bottom .playing {
    display: flex;
    flex: 1 1; }
    .bottom .playing .artwork {
      width: 140px;
      background-size: cover;
      background-position: 50% 50%;
      z-index: 1; }
    .bottom .playing .info {
      width: 240px;
      box-sizing: border-box;
      overflow: hidden;
      padding: 10px 20px;
      padding-right: 0;
      margin-right: 40px; }
      @media (max-width: 750px) {
        .bottom .playing .info {
          margin-right: 0;
          width: 180px; } }
      @media (max-width: 650px) {
        .bottom .playing .info {
          display: none; } }
      .bottom .playing .info > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .bottom .playing .info .playing-title {
        font-size: 18px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.87); }
      .bottom .playing .info .playing-author {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
        color: rgba(0, 0, 0, 0.7); }
      .bottom .playing .info .playing-list {
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.38); }
    .bottom .playing .dash {
      display: flex;
      justify-content: center;
      flex-direction: column;
      -webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
      transition: opacity .2s ease, -webkit-transform .2s ease;
      transition: transform .2s ease, opacity .2s ease;
      transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease; }
      .bottom .playing .dash.dash-hidden {
        -webkit-transform: translateX(-80px);
                transform: translateX(-80px);
        opacity: 0;
        pointer-events: none; }
    .bottom .playing .control {
      padding: 0 20px 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .bottom .playing .control .material-icons {
        margin: 0;
        font-size: 32px; }
      .bottom .playing .control .spacer {
        width: 20px; }
  .bottom .spanner {
    flex: 1 1; }
  .bottom .actions {
    padding: 20px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 650px) {
      .bottom .actions {
        padding: 10px; } }
    .bottom .actions > a {
      font-size: 0; }
    .bottom .actions .material-icons {
      margin-left: 0;
      margin: 2.5px 0; }
  .bottom .volume {
    display: flex;
    align-items: center;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
    margin-right: 10px;
    -webkit-transform: none;
            transform: none; }
    .bottom .volume .volume-anchor {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 0;
      overflow: visible;
      opacity: 0;
      -webkit-transition: opacity .2s ease;
      transition: opacity .2s ease;
      padding: 0 10px 16px 10px;
      box-sizing: border-box;
      display: flex;
      width: 80px;
      flex-direction: column;
      justify-content: center;
      pointer-events: none; }
    .bottom .volume .volume-text {
      margin-bottom: 2px; }
    .bottom .volume .volume-bar {
      width: 60px;
      height: 5px;
      background: #9d9d9d;
      overflow: hidden;
      cursor: pointer; }
      .bottom .volume .volume-bar .volume-inner {
        width: 60px;
        height: 100%;
        background: #00bcd4; }
    .bottom .volume.volume-shown {
      -webkit-transform: translateX(-80px);
              transform: translateX(-80px); }
      .bottom .volume.volume-shown .volume-anchor {
        opacity: 1;
        pointer-events: all; }

/* Shared */
.list .list-loading, .list .list-empty, .storage .storage-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.38);
  -webkit-transition: color .2s ease;
  transition: color .2s ease; }
  .list .list-loading:hover, .list .list-empty:hover, .storage .storage-loading:hover {
    color: rgba(0, 0, 0, 0.54); }
  .list .list-loading .material-icons, .list .list-empty .material-icons, .storage .storage-loading .material-icons {
    font-size: 160px; }
  .list .list-loading .splash-hint, .list .list-empty .splash-hint, .storage .storage-loading .splash-hint {
    font-size: 18px; }

/* Home */
.home {
  padding: 40px 0; }
  .home .title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 40px; }
    .home .title > .material-icons {
      font-size: 48px;
      margin-right: 20px; }
  .home .jump-input {
    margin-bottom: 40px; }
  .home .recent {
    width: 100%;
    box-sizing: border-box;
    border-left: rgba(0, 188, 212, 0.5) 10px solid;
    padding: 15px 40px;
    margin-bottom: 20px;
    -webkit-transition: border-left-color .2s ease;
    transition: border-left-color .2s ease; }
    .home .recent:hover {
      border-left-color: #00bcd4; }
    .home .recent .recent-name {
      color: rgba(0, 0, 0, 0.87);
      line-height: 32px;
      font-size: 24px;
      font-weight: bold; }
    .home .recent .recent-id {
      color: rgba(0, 0, 0, 0.38);
      line-height: 20px;
      font-size: 16px; }

.home-btn {
  margin-right: 20px; }

/* Lists */
.list .list-loading {
  height: calc(100vh - 105px - 40px); }

.list .list-empty {
  height: calc(100vh - 105px - 40px - 60px);
  cursor: pointer; }
  .list .list-empty.list-empty-disabled {
    cursor: default; }
    .list .list-empty.list-empty-disabled:hover {
      color: rgba(0, 0, 0, 0.38); }

.list .entry {
  display: flex;
  height: 100px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 6px;
  margin-bottom: 10px;
  -webkit-transition: box-shadow .2s ease, -webkit-transform .2s ease;
  transition: box-shadow .2s ease, -webkit-transform .2s ease;
  transition: box-shadow .2s ease, transform .2s ease;
  transition: box-shadow .2s ease, transform .2s ease, -webkit-transform .2s ease;
  overflow: hidden; }
  .list .entry .entry-border {
    background-color: #00bcd4;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    opacity: 1;
    -webkit-transition: opacity .2s ease, -webkit-filter .2s ease;
    transition: opacity .2s ease, -webkit-filter .2s ease;
    transition: filter .2s ease, opacity .2s ease;
    transition: filter .2s ease, opacity .2s ease, -webkit-filter .2s ease;
    width: 10px; }
  .list .entry.active .entry-border {
    -webkit-filter: none;
            filter: none; }
  .list .entry.moving {
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    z-index: 10; }
    .list .entry.moving .entry-border {
      opacity: .5; }
  .list .entry .entry-info {
    padding: 10px 0 10px 20px;
    min-width: 0;
    flex: 1 1;
    white-space: nowrap; }
  .list .entry .entry-title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.87);
    overflow: hidden;
    text-overflow: ellipsis; }
  .list .entry .entry-title-group {
    height: 35px;
    margin-bottom: 5px; }
    .list .entry .entry-title-group .entry-title-hint {
      line-height: 10px;
      font-size: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.54); }
    .list .entry .entry-title-group .entry-title-main {
      line-height: 25px;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.87); }
  .list .entry .entry-author {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center; }
    .list .entry .entry-author .material-icons {
      line-height: 20px;
      font-size: 18px;
      margin-right: 2px; }
  .list .entry .entry-hint {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.38);
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center; }
    .list .entry .entry-hint .entry-link-text {
      text-decoration: underline; }
    .list .entry .entry-hint .material-icons {
      line-height: 20px;
      font-size: 18px;
      margin-right: 2px; }
  .list .entry .entry-artwork {
    width: 140px;
    height: 100px;
    -webkit-transition: -webkit-filter .5s ease;
    transition: -webkit-filter .5s ease;
    transition: filter .5s ease;
    transition: filter .5s ease, -webkit-filter .5s ease; }
  .list .entry .entry-artwork-internal {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%; }
  .list .entry .entry-artwork-loading {
    background: #EEE;
    display: flex;
    align-items: center;
    justify-content: center; }
  .list .entry .entry-desc-loading {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .list .entry .entry-actions {
    padding: 10px 20px 10px 0; }
    .list .entry .entry-actions .material-icons {
      display: block;
      font-size: 20px;
      margin-bottom: 10px; }
      .list .entry .entry-actions .material-icons:last-child {
        margin-bottom: 0px; }
  .list .entry.entry-not-ready .entry-artwork {
    -webkit-filter: grayscale(1);
            filter: grayscale(1); }

.list.updating .entry {
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow; }

.list.can-drag .entry.moving .entry-artwork {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.list.can-drag .entry .entry-artwork {
  cursor: -webkit-grab;
  cursor: grab; }

/* Storaget */
.storage .storage-loading {
  height: calc(100vh - 105px - 40px - 60px);
  width: 100%; }

.storage .storage-entry {
  width: 100%;
  box-sizing: border-box;
  border-left: rgba(0, 188, 212, 0.5) 10px solid;
  padding: 15px 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center; }

.storage .storage-info {
  flex: 1 1;
  overflow: hidden;
  margin-right: 10px; }

.storage .storage-title-small {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.storage .storage-title {
  color: rgba(0, 0, 0, 0.87);
  line-height: 32px;
  font-size: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.storage .storage-size {
  color: rgba(0, 0, 0, 0.87);
  line-height: 20px;
  font-size: 16px;
  margin-top: 10px; }

.storage .storage-delete {
  color: black;
  opacity: .38;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease; }
  .storage .storage-delete:hover {
    opacity: .87;
    cursor: pointer; }

/* Bottom bar icon-box */
.icon-box {
  width: 32px;
  height: 32px;
  position: relative; }
  .icon-box > * {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    z-index: 1; }
    .icon-box > *.icon-box-hidden {
      opacity: 0;
      pointer-events: none;
      z-index: 0;
      -webkit-transition-timing-function: ease-in;
              transition-timing-function: ease-in; }
      .bottom .playing .control .icon-box > *.icon-box-hidden {
        opacity: 0; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIcons-Regular.e79bfd88.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/static/media/MaterialIcons-Regular.570eb838.woff2) format("woff2"), url(/static/media/MaterialIcons-Regular.012cf6a1.woff) format("woff"), url(/static/media/MaterialIcons-Regular.a37b0c01.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

.title .actions .material-icons {
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  color: black;
  opacity: .54;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease; }
  .title .actions .material-icons:hover {
    opacity: .87; }
  .title .actions .primary.material-icons {
    opacity: .7;
    color: #00bcd4; }
    .title .actions .primary.material-icons:hover {
      opacity: 1; }
  .title .actions .disabled.material-icons {
    cursor: default;
    opacity: .20; }
    .title .actions .disabled.material-icons:hover {
      opacity: .20; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

.rotate {
  -webkit-animation: rotate linear 1s infinite;
          animation: rotate linear 1s infinite; }

.App {
  text-align: center; }

/* Normalize */
body {
  margin: 0; }

a {
  text-decoration: none;
  color: inherit; }

/* Shared */
.title, .dialog-backdrop .dialog .dialog-title {
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px; }
  .title > .material-icons, .dialog-backdrop .dialog .dialog-title > .material-icons {
    font-size: 36px;
    margin-right: 10px; }
  .title .title-content, .dialog-backdrop .dialog .dialog-title .title-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1; }

/* General */
.title .actions {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  align-items: center; }
  .title .actions .icon-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
    white-space: nowrap; }
    .title .actions .icon-group .material-icons {
      margin-right: 5px; }
    .title .actions .icon-group:hover {
      color: rgba(0, 0, 0, 0.87); }
      .title .actions .icon-group:hover .material-icons {
        opacity: .87; }
  .title .actions > a {
    display: inline-flex;
    align-items: center; }

input {
  width: 100%;
  border: 0;
  padding: 0;
  margin-bottom: 10px;
  line-height: 32px;
  font-size: 16px;
  outline: 0;
  border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
  -webkit-transition: border-bottom-color .2s ease;
  transition: border-bottom-color .2s ease; }
  input:focus {
    border-bottom-color: #00bcd4; }
  input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  input::placeholder {
    color: rgba(0, 0, 0, 0.38); }

.input-hint {
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.38); }

button {
  border: 0;
  outline: 0;
  background: #00bcd4;
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 6px;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  font-weight: bold;
  -webkit-transition: background-color .2s ease, box-shadow .2s ease;
  transition: background-color .2s ease, box-shadow .2s ease; }
  button:hover {
    background-color: #008fa1; }
  button:disabled {
    box-shadow: none;
    background-color: #9d9d9d; }

.dialog-backdrop {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity .5s ease-in;
  transition: opacity .5s ease-in;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; }
  .dialog-backdrop.dialog-active {
    opacity: 1;
    pointer-events: all;
    -webkit-transition: opacity .5s ease-out;
    transition: opacity .5s ease-out; }
  .dialog-backdrop .dialog {
    padding: 20px;
    width: 400px;
    box-sizing: border-box;
    background: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px; }
    .dialog-backdrop .dialog .dialog-title {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.87); }
      .dialog-backdrop .dialog .dialog-title > .material-icons {
        font-size: 24px;
        margin-right: 5px; }
    .dialog-backdrop .dialog .dialog-actions {
      margin-top: 10px; }

.loading {
  border: #00bcd4 5px solid;
  border-bottom: transparent 5px solid;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  -webkit-animation: loading 1.5s linear infinite;
          animation: loading 1.5s linear infinite; }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

