$bottom-height: 105px;
$max-width: 700px;

$primary: #00bcd4;

$threshold: 650px;

$volume-width: 60px;

$floating-text-height: 14px;
